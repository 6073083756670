import { Divider, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Link } from "gatsby-theme-material-ui"
import React from "react"
import theme from "../theme"

const SearchResultItem = props => {
  const { result } = props

  const truncate = str => {

    if (str.length < 50) {
      return ""
    }

    return str.length > 200 ? str.substring(0, 750) + "..." : str
  }

  return (
    <Box mt={theme.spacing(2)}>
      <Link to={result.path} underline="hover">
        <Typography
          variant="body1"
          component="div"
          color="secondary.dark"
          sx={{
            mb: theme.spacing(1),
            lineHeight: { md: "1.375rem" },
            fontSize: { md: "1.125rem" },
          }}
        >
          {result.title}
        </Typography>
      </Link>
      <Typography
        variant="body1"
        component="p"
        sx={{ fontSize: { md: "0.875rem" }, lineHeight: { md: "1.375rem" } }}
      >
        {truncate(result.intro)}
      </Typography>
      <Divider sx={{ mt: theme.spacing(2) }} />
    </Box>
  )
}

export default SearchResultItem
