import styled from "@emotion/styled"
import {
  alpha,
  Grid,
  InputAdornment,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { Container } from "@mui/system"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Link } from "gatsby-theme-material-ui"
import i18next, { t } from "i18next"
import React, { useEffect } from "react"
import { useFlexSearch } from "react-use-flexsearch"
import KnetIcon from "../components/KnetIcon"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import SearchResultItem from "../components/SearchResultItem"
import Seo from "../components/Seo"
import withTrans from "../components/withTrans"
import theme from "../theme"

const StyledInputBase = styled(OutlinedInput)(({ theme }) => ({
  color: "inherit",
  borderRadius: "8px",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  "& .MuiOutlinedInput-input": {
    padding: theme.spacing(1.5, 1, 1.5, 2),
    // vertical padding + font size from searchIcon
    width: "100%",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "8px",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
      "&:focus": {},
    },
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
    opacity: 1,
  },
  "&:hover fieldset": {
    // borderColor: "red !important",
  },
  "& fieldset": {
    borderColor: "#D0D5DD !important",
  },
  "&.Mui-focused fieldset": {
    borderColor: "#667085 !important",
    borderWidth: "1px !important",
  },
}))

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginBottom: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0),
    width: "auto",
  },
}))

const SearchPage = ({
  data: {
    localSearchFoods: { index, store },
  },
}) => {
  const itemsPerPage = 5
  const location = useLocation()
  const { search } = location
  const query = new URLSearchParams(search).get("s")
  const qpage = new URLSearchParams(search).get("p")
  const [searchQuery, setSearchQuery] = React.useState(query || "")
  const [page, setPage] = React.useState(qpage * 1 || 1)
  const upMd = useMediaQuery(theme.breakpoints.up("md"))
  const results = useFlexSearch(searchQuery, index, store)
  const [filteredResults, setFilteredResults] = React.useState(results)
  const [pages, setPages] = React.useState(0)

  useEffect(() => {
    setPages(Math.round(results.length / itemsPerPage))
    setFilteredResults(
      results.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      )
    )
    const qqpage = new URLSearchParams(search).get("p")
    setPage(qqpage * 1 || 1)
  }, [results, page, location, search])

  return (
    <Layout theme={theme}>
      <Container>
        <PageHeading title={t("seach_page.heading")} />
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems={upMd ? "center" : "stretch"}
        >
          <Grid item xs={12} md={3} mb={theme.spacing(4)}>
            <Search>
              <form action="/search" method="get" autoComplete="off">
                <StyledInputBase
                  placeholder={t("search.general.placeholder")}
                  title={t("search.general.placeholder")}
                  fullWidth={upMd ? false : true}
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  id="search-input"
                  name="s"
                  onInput={e => setSearchQuery(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton size="small" edge="end">
                        <KnetIcon
                          viewBox="0 0 20 20"
                          icon="search"
                          color={theme.palette.text.light}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </form>
              <Typography
                variant="body2"
                component="div"
                sx={{
                  mt: theme.spacing(1),
                  fontSize: { xs: "0.875rem" },
                  lineHeight: { md: "1.25rem" },
                }}
              >
                {searchQuery.length > 0 &&
                  results.length === 0 &&
                  t("search.results.no_results")}
                {results.length === 1 && t("search.results.one_result")}
                {results.length > 1 &&
                  t("search.results.multiple_results", {
                    from: (page - 1) * itemsPerPage + 1,
                    to:
                      results.length < (page - 1) * itemsPerPage + itemsPerPage
                        ? results.length
                        : (page - 1) * itemsPerPage + itemsPerPage,
                    total: results.length,
                  })}
              </Typography>
            </Search>
          </Grid>
        </Grid>

        {filteredResults.map(res => (
          <SearchResultItem result={res} />
        ))}

        <Stack my={theme.spacing(2)}>
          <Pagination
            count={pages}
            page={page}
            renderItem={item => (
              <PaginationItem
                component={Link}
                to={`/search?s=${query}${
                  item.page === 1 ? "" : `&p=${item.page}`
                }`}
                {...item}
              />
            )}
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          />
        </Stack>
      </Container>
    </Layout>
  )
}
export default withTrans(SearchPage)

export const Head = () => (
  <Seo title={i18next.t("search.title")} robots="noindex, follow" />
)

export const pageQuery = graphql`
  query {
    localSearchFoods {
      index
      store
    }
  }
`
